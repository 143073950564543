<template>
  <!-- 卡密 - 卡密发放列表 - 明细 -->
  <div class="pageContol listWrap grantListSeeDetailed">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">卡密管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">卡密发放列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">卡密发放明细</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl"
          style="display: flex; flex-direction: column; align-items: flex-start"
        >
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="卡号" class="searchboxItem ci-full">
              <span class="itemLabel">卡号:</span>
              <el-input
                size="small"
                v-model="queryData.keyNo"
                type="text"
                placeholder="请输入卡号"
                clearable
              />
            </div>
            <div title="卡密" class="searchboxItem ci-full">
              <span class="itemLabel">卡密:</span>
              <el-input
                size="small"
                v-model="queryData.keyPwd"
                type="text"
                placeholder="请输入卡密"
                clearable
              />
            </div>
            <div title="兑换人姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 8rem">兑换人姓名:</span>
              <el-input
                size="small"
                v-model="queryData.customerName"
                type="text"
                placeholder="请输入兑换人姓名"
                clearable
              />
            </div>
            <div title="兑换人手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 9rem">兑换人手机号:</span>
              <el-input
                size="small"
                v-model="queryData.mobile"
                type="text"
                placeholder="请输入兑换人手机号"
                clearable
              />
            </div>
          </div>
          <div class="searchbox">
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">状态:</span>
              <el-select
                v-model="queryData.keyState"
                placeholder="请选择状态"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in keyStates"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div title="是否启用" class="searchboxItem ci-full">
              <span class="itemLabel">是否启用:</span>
              <el-select
                v-model="queryData.keyEnabled"
                placeholder="请选择是否启用"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in keyEnabledList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="兑换日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 7rem">兑换日期:</span>
              <el-date-picker
                v-model="queryData.exchangeDate"
                type="datetimerange"
                size="small"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                min-width="60"
              ></el-table-column>
              <el-table-column
                label="卡号"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="keyNo"
              ></el-table-column>
              <el-table-column
                label="卡密"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="keyPwd"
              ></el-table-column>
              <el-table-column
                label="兑换人姓名"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="customerName"
              ></el-table-column>
              <el-table-column
                label="兑换人手机号"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="mobile"
              ></el-table-column>
              <el-table-column
                label="兑换日期"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="useTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.useTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="状态"
                align="left"
                show-overflow-tooltip
                min-width="80"
                prop="keyStateName"
              >
                <template slot-scope="scope">
                  {{ scope.row.keyStateName }}
                </template>
              </el-table-column>
              <el-table-column
                label="启用/禁用"
                align="left"
                show-overflow-tooltip
                min-width="80"
              >
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.keyStateName == '已使用' ||
                      scope.row.keyStateName == '已过期'
                    "
                    >--</span
                  >
                  <div v-else>
                    <el-switch
                      :width="20"
                      v-model="scope.row.enabled"
                      :active-value="1"
                      :inactive-value="0"
                      @change="
                        getSwitchUsable(scope.row.keyId, scope.row.enabled)
                      "
                    ></el-switch>
                    <span style="margin-left: 4px">{{
                      scope.row.enabled != 0 ? "启用" : "禁用"
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                min-width="100"
                fixed="right"
                ><template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="downloadCard(scope.row.keyId)"
                    >下载卡面</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "grantListSeeDetailed",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  beforeRouteLeave: resetKeepAlive,
  data() {
    return {
      // 查询数据
      queryData: {
        keyNo: "", // 卡号
        keyPwd: "", // 卡密
        customerName: "", // 兑换人姓名
        mobile: "", // 兑换人手机号
        keyState: "", // 状态
        exchangeDate: "", // 兑换日期
        keyEnabled: "",
      },
      // 卡密状态 - 下拉数据
      keyStates: [],
      keyEnabledList: [
        {
          value: 1,
          label: "已启用",
        },
        {
          value: 0,
          label: "已禁用",
        },
      ],
    };
  },
  watch: {},
  methods: {
    // 获取 - 卡密状态 - 下拉数据
    getnoticeify() {
      const list = this.$setDictionary("KEYSTATE", "list") || [];
      for (let key in list) {
        this.keyStates.push({ id: key, name: list[key] });
      }
    },
    // 获取发放卡密列表
    getData(pageNum = 1) {
      const params = {
        walletId: this.$route.query.walletId,
        pageNum: pageNum,
        pageSize: this.pageSize,
        keyNo: this.queryData.keyNo,
        keyPwd: this.queryData.keyPwd,
        customerName: this.queryData.customerName,
        mobile: this.queryData.mobile,
        enabled: this.queryData.keyEnabled,
      };
      if (this.queryData.keyState) {
        params.keyState = this.queryData.keyState;
      }
      if (this.queryData.exchangeDate) {
        params.startDate = this.queryData.exchangeDate[0];
        params.endDate = this.queryData.exchangeDate[1];
      }
      this.doFetch(
        {
          url: "/biz/card/key/queryList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 下载卡面
    downloadCard(id) {
      this.$post(
        "/biz/card/wallet/downloadCard",
        {
          keyId: id,
        },
        3000,
        false,
        2
      )
        .then((res) => {
          if (res.status == 0) {
            window.location.href = res.data;
          } else {
            this.$message.warning(res.message);
          }
        })
        .catch(() => {});
    },
    /* 打印权限 */
    getSwitchUsable(keyId, enabled) {
      this.$post(
        "/biz/card/key/enabled",
        {
          keyId,
          enabled,
        },
        3000,
        false,
        2
      )
        .then((ret) => {
          this.$message({
            type: "success",
            message: ret.message,
          });
          this.getData(-1);
        })
        .catch((err) => {
          return;
        });
    },
  },
  computed: {},
  mounted() {},
  created() {
    this.getnoticeify();
  },
};
</script>
  
 <style lang="less" scoped>
.grantListSeeDetailed {
}
</style>
  